//
// Content
//

// Desktop mode
@include media-breakpoint-up(lg) {
  .content {
    // Aside enabled mode
    .aside-enabled & {
      padding-left: get($content-spacing, desktop);
      border-left: 1px solid #f4f4f4;
    }
  }
}
