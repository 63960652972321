.btn-white {
  background-color: #ffffff !important;
  color: #7e8299 !important;
  &:hover {
    color: #181c32 !important;
    background-color: #e8e9ed !important;
  }
  &:disabled {
    color: #b5b8c3 !important;
    background: #e8e9ed !important;
  }
}
