.custom-toast-body .Toastify__toast {
  box-shadow: none;
}

.custom-toast-body {
  width: 405px !important;
  .Toastify__toast-theme--colored {
    &.Toastify__toast--info {
      background: #ffffff;
      border-radius: 8px;
      color: #181c32;
      border: none;
      box-shadow: 23.4866px 32.3787px 94px rgba(25, 36, 64, 0.1),
        4.69732px 6.47574px 15.275px rgba(25, 36, 64, 0.05);
      .Toastify__close-button {
        color: #7e8299;
      }
      .Toastify__toast-icon {
        width: 23px;
        height: 23px;
      }
      .Toastify__toast-body div {
        margin-right: 8px;
        font-weight: 400;
        font-size: 14px;
        line-height: 23px;
        color: #181c32;
      }
    }
    &.Toastify__toast--success {
      background: #ffffff;
      border-radius: 8px;
      color: #181c32;
      border: none;
      .Toastify__close-button {
        color: #7e8299;
      }
      .Toastify__toast-icon {
        width: 23px;
        height: 23px;
      }
      .Toastify__toast-body div {
        margin-right: 8px;
        font-weight: 600;
        font-size: 14px;
        line-height: 23px;
        color: #47be7d;
      }
    }
    &.Toastify__toast--error {
      background: #ffffff;
      border-radius: 8px;
      color: #181c32;
      border: none;
      box-shadow: 23.4866px 32.3787px 94px rgba(25, 36, 64, 0.1),
        4.69732px 6.47574px 15.275px rgba(25, 36, 64, 0.05);
      .Toastify__close-button {
        color: #7e8299;
      }
      .Toastify__toast-icon {
        width: 23px;
        height: 23px;
      }
      .Toastify__toast-body div {
        margin-right: 8px;
        font-weight: 600;
        font-size: 14px;
        line-height: 23px;
        color: #f1416c;
      }
    }
    &.Toastify__toast--warning {
      background: #ffffff;
      border-radius: 8px;
      color: #181c32;
      border: none;
      box-shadow: 23.4866px 32.3787px 94px rgba(25, 36, 64, 0.1),
        4.69732px 6.47574px 15.275px rgba(25, 36, 64, 0.05);
      .Toastify__close-button {
        color: #7e8299;
      }
      .Toastify__toast-icon {
        width: 23px;
        height: 23px;
      }
      .Toastify__toast-body div {
        margin-right: 8px;
        font-weight: 400;
        font-size: 14px;
        line-height: 23px;
        color: #181c32;
      }
    }
  }
  .Toastify__progress-bar-theme--colored {
    &.Toastify__progress-bar--info {
      background: #ffffff;
    }
    &.Toastify__progress-bar--success {
      background: #47be7d;
    }
    &.Toastify__progress-bar--error {
      background: #f1416c;
    }
    &.Toastify__progress-bar--warning {
      background: #ffffff;
    }
  }
}

.text-error {
  width: inherit;
}
