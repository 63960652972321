:root {
  --white: #ffffff;
  --primary: #0080f0;
  --success: #47be7d;
  --info: #7239ea;
  --warning: #edb32b;
  --danger: #f1416c;
  --gray-200: #eff2f5;
  --gray-300: #e4e6ef;
  --gray-400: #b5b5c3;
  --gray-700: #5e6278;
  --gray-800: #3f4254;
  --neutral-1: #f7f9fc;
  --neutral-2: #f2f4f7;
  --neutral-2-5: #ebedf2;
  --neutral-3: #e8e9ed;
  --neutral-4: #d9dbe4;
  --neutral-6: #a1a5b7;
  --neutral-7: #7e8299;
  --neutral-10: #181c32;
  --primary-2: #f0f9ff;
  --primary-3: #e0f1ff;
  --primary-5: #a7d6ff;
  --primary-6: #49a3f1;
  --primary-7: #8ab1d6;
  --primary-8: #006fde;
  --warning-active: #e1a416;
  --warning-active-2: #d39400;
  --warning-2: #fff5dd;
  --warning-light-3: #faeed2;
  --success-2: #e8fff3;
  --success-3: #50cd89;
  --success-light-1-5: #d9fcea;
  --success-light-2: #d0f5e2;
  --danger-light: #fff5f8;
  --danger-active: #d9214e;
  --danger-light-1-5: #ffebf1;
  --danger-light-2: #fae3ea;
  --danger-dark: #9f1f40;
}
