// Layout
@import './toastify.scss';
@import './button.scss';
@import './sims-theme.scss';

.rbc-overlay-header {
  display: none;
}

.rbc-overlay {
  filter: drop-shadow(23.4866px 32.3787px 94px rgba(25, 36, 64, 0.1))
    drop-shadow(4.69732px 6.47574px 15.275px rgba(25, 36, 64, 0.05))
    drop-shadow(0.4px 0.533333px 5px rgba(25, 36, 64, 0.05));
  border-radius: 8px;
  box-shadow: none !important;
  border: none !important;
  padding: 6px 12px 12px !important;
  max-height: 408px;
  overflow: auto;
  min-width: 160px !important;
}

.tippy-box[data-theme~='dark'] {
  background-color: #181c32;
  color: #fff;
  border-radius: 6px;
  .tippy-arrow::before {
    border-top-color: #181c32;
  }
}

.tippy-box[data-theme~='light'] {
  border-radius: 6px;
  box-shadow: 0 0 40px 0 rgb(82 63 105 / 20%);
  border: none !important;
  padding: 6px 12px 12px !important;
  max-height: 408px;
  overflow: auto;
  min-width: 210px !important;
}

.tippy-box[data-theme~='light'] .tippy-content {
  padding: 0;
}
.tippy-box[data-theme~='light'] .rbc-event.view-more {
  height: 22px;
  font-size: 12px;
  line-height: 20px;
  margin-top: 2px;

  &:not(.normal)::after {
    content: '';
    background-size: 17px 17px;
    height: 17px;
    width: 17px;
  }
}

.tippy-box[data-theme~='dark-2'] {
  min-width: 280px !important;
  background-color: #181c32;
  padding: 6px 10px;
  border-radius: 8px;
  .tippy-arrow::before {
    border-top-color: #181c32;
  }
}

.tippy-box[data-theme~='light-menu'] {
  box-shadow: 23.4866px 32.3787px 94px rgba(0, 0, 0, 0.1),
    4.69732px 6.47574px 15.275px rgba(0, 0, 0, 0.05);
  padding: 8px;
  background-color: #fff;
  color: #181c32;
  font-size: 14px;
  font-family: 'Archivo', sans-serif;
  line-height: 23px;
  min-width: 160px;

  .tippy-content {
    padding: 0;

    .dd-menu-sr-item {
      padding: 8px;
      cursor: pointer;
      border-radius: 4px;
      user-select: none;
      &:hover {
        background-color: #f7fcff;
        color: #0080f0;
      }
    }
  }
}

.tippy-box .rbc-event .rbc-event-content {
  line-height: 16px;
}

.rbc-event {
  font-size: 13px;
  font-weight: 600;
  font-family: 'Archivo', sans-serif;
  line-height: 22px;
  border-radius: 6px;
  padding: 3px 10px;
  display: flex;
  justify-content: space-between;

  &:focus {
    outline: none !important;
  }

  .rbc-event-content {
    color: #ffffff;
    font-size: 13px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:not(.normal) > div {
    max-width: calc(100% - 24px);
  }

  &:not(.normal)::after {
    content: '';
    // position: absolute;
    background-size: 20px 20px;
    height: 20px;
    width: 20px;
  }

  &.stock-level-par::after {
    background-image: url('../assets/icons/stock-level/alert-gray.svg');
    margin: auto;
    margin-right: 0;
  }
  &.stock-level-restock::after {
    background-image: url('../assets/icons/stock-level/alert-yellow.svg');
    margin: auto;
    margin-right: 0;
  }
  &.stock-level-alarm::after {
    background-image: url('../assets/icons/stock-level/alert-red.svg');
    margin: auto;
    margin-right: 0;
  }
}

.rbc-popup-title {
  font-family: 'Archivo', sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
}

.rbc-overlay > * + * {
  margin-top: 6px !important;
}

.rbc-event-tag-1:hover {
  background-color: #7e8299 !important;
}
.rbc-event-tag-2:hover {
  background-color: #006fde !important;
}
.rbc-event-tag-3:hover {
  background-color: #e1a416 !important;
}
.rbc-event-tag-4:hover {
  background-color: #47be7d !important;
}
.rbc-event-tag-5:hover {
  background-color: #d9214e !important;
}
.rbc-event-tag-1 {
  background-color: #a1a5b7 !important;
}
.rbc-event-tag-2 {
  background-color: #0080f0 !important;
}
.rbc-event-tag-3 {
  background-color: #edb32b !important;
}
.rbc-event-tag-4 {
  background-color: #50cd89 !important;
}
.rbc-event-tag-5 {
  background-color: #f1416c !important;
}

.text-overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
}

.number-width-5 {
  width: 5ch !important;
}

/*disable default number input appearance*/

/*Chrome, Edge, Opera, Safari*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

/*Firefox*/

input[type='number'] {
  -moz-appearance: textfield;
}

/* Disable default styling for text inputs */
input[type='text'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/* Disable default styling for password inputs */
input[type='password'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/* Optional: Disable spinners for number inputs in other browsers */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Optional: Disable clear icon for text inputs in other browsers */
input[type='search']::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

//Hide Safari's buid-in password reccommend icon
input::-webkit-credentials-auto-fill-button {
  visibility: hidden;
}

//Hide Safari's buid-in name/e-mail reccommend icon
input::-webkit-contacts-auto-fill-button {
  visibility: hidden;
}

@-moz-document url-prefix() {
  *::-moz-scrollbar {
    width: 100;
  }
}
